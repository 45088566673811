.main-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  flex-grow: 1;
  flex-basis: calc(100% - 370px);
  margin-right: 16px;
}

.title-tile,
.features,
.rooms {
  margin: 24px 0;
  text-align: left;
}

hr.last-hr {
  display: none;
}

.content hr,
.main-content hr,
hr.main-content {
  margin-left: auto;
  margin-right: auto;
  margin: 0;
  border: 0;
  height: 1px;
  background-color: rgb(221, 221, 221);
}

hr.main-content {
  margin: 15px 0;
}

@media (max-width: 1149px) {
  .main-content {
    flex-direction: column; /* Stacks content on smaller screens */
  }

  hr.last-hr {
    display: block;
  }

  .content {
    flex-basis: auto; /* Resets the width to auto on smaller screens */
    width: 100%; /* Full width on smaller screens */
  }
}
