.form-label {
  color: rgb(34, 34, 34);
  display: block;
  font-size: 10px;
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
