.input-timezone {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input-timezone label {
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
  font-weight: 600;
}

.input-timezone select {
  font-size: 16px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.input-timezone select:focus {
  outline: none;
  border-color: #007bff;
}
