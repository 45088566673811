.booking-widget-container {
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 8px;
  width: 370px;
  align-self: flex-start;
  align-items: center;

  margin: 12px 0 12px 12px;
  padding: 18px;

  border: solid 1px rgb(221, 221, 221);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px 0px;
  box-sizing: border-box;

  color: rgb(34, 34, 34);
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-break: strict;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}

/* Use a media query for smaller screens */
@media (max-width: 1149px) {
  .booking-widget-container {
    flex-basis: auto; /* Resets the width to auto on smaller screens */
    width: calc(100vw - 30px); /* Full width on smaller screens */
    margin: 15px 0px;
  }
}

.booking-widget-container > .booking-button {
  width: 87%;
  max-width: 200px;
}
