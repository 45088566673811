.generic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.generic-modal-content {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;

  padding: 46px 16px 37px 16px;

  border-radius: 12px;
  background: white;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;

  font-size: 1.3em;
  line-height: 1.3em;

  width: 70%;
  max-height: 80vh;
  overflow-x: clip;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Use a media query for smaller screens */
@media (max-width: 600px) {
  .generic-modal-content {
    font-size: 1em;
    line-height: 1em;
  }
}
