.share-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.share-button:hover {
  background-color: rgb(247, 247, 247);
}

.share-button svg {
  width: 16px;
  height: 16px;
}
