.share-option {
  align-items: flex-start;
  appearance: button;
  background-color: rgba(0, 0, 0, 0);
  border: solid 0.909px rgb(221, 221, 221);
  color: rgb(34, 34, 34);
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  height: 75px;
  line-height: 20.02px;
  width: 250px;
  transition: background-color 0.2s;
  margin: 12px 12px 0 0;
}

.share-option:hover {
  background-color: rgb(247, 247, 247) !important;
}

.share-option-text {
  float: left;
  font-size: 17px;
  font-weight: 600;
  padding: 6px 0 0 6px;
}
