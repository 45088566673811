.input-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 5px;
}

.input-text label {
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
  font-weight: 600;
}

.input-text input {
  font-size: 16px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.input-text input:focus {
  outline: none;
  border-color: #007bff;
}

.input-text input::placeholder {
  color: #888;
}
