.modal-close-button {
  position: absolute;
  top: 0px;
  left: 6px;
  border: none;
  background: none;
  font-size: 38px;
  cursor: pointer;
  display: flex;
}
