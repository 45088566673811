.feature-container {
    column-gap: 20px;
    display: flex;
    margin: 20px 0;
}

.feature-icon {
    width: 22px;
    display: flex;
    justify-content: center;
}

.feature-container svg {
    width: 22px;
    height: 22px;
}

.feature-title h3 {
    font-size: 1.1rem;
    line-height: 1.45rem;
    margin: 0 0 4px 0;
    text-align: left;
}

.feature-description {
    color: rgb(113, 113, 113);
    font-weight: 400;
    font-size: 0.975rem;
    line-height: 1.25rem;
}