.expandable-description-container {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0px;
}

.expandable-description-container p {
  margin: 0 0 16px 0;
}

.description {
  text-align: justify;
  max-height: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipses {
  color: black;
}

.view-more {
  display: block;
  margin: 0 auto;
  padding: 10px 0px;
  color: rgb(34, 34, 34);
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: underline;
}

.modal-content {
  margin: 14px 18px 0px 18px;
}
