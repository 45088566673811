.booking-dates-guests-display {
  display: flex;
  border: solid 1px rgb(180, 180, 180);
  text-align: left;
  border-radius: 8px;
  padding: 15px;
}

@media (max-width: 449px) {
  .booking-dates-guests-display {
    flex-direction: column;
  }
  .date-range-display {
    margin-bottom: 15px;
  }
}
