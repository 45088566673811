.booking-button {
  background-image: radial-gradient(#c7981d, #e0b740);
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 20px;
  border-radius: 8px;
  border: none;
  margin: 18px 0;
}
