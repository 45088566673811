.image-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.thumbnail-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.main-image img,
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.show-all-photos {
  position: absolute;
  bottom: 14px;
  right: 14px;
  color: rgb(34, 34, 34);
  border: solid 1px black;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  background-color: white;
}

.show-all-photos:hover {
  background-color: rgb(247, 247, 247);
}
