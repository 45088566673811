.feature-icon {
  height: 20px;
  width: 20px;
  display: block;
  fill: currentcolor;
}

.share-icon {
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
}
