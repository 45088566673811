.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  background-color: #fff;
}

.listing-title {
  margin: 0;
  color: rgb(34, 34, 34);
  font-size: 2em;
  font-weight: 600;
  line-break: strict;
  line-height: 30px;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
}
